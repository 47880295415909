import instance from './service';

//获取验证码
export const reqSmsCode = (params) => {
  return instance({
    url: '/index/phoneCode',
    method: 'post',
    params,
    token: false,
  });
};
//手机号登录
export const reqLoginPhone = (params) => {
  return instance({
    url: '/index/phoneLogin',
    method: 'post',
    params,
    token: false,
  });
};
//排行榜
export const reqRank = (params) => {
  return instance({
    url: '/general/rank',
    method: 'get',
    params,
  });
};
//我的作品
export const reqMyWork = (params) => {
  return instance({
    url: '/general/my',
    method: 'get',
    params,
  });
};
// 投稿/编辑
export const reqSave = (params) => {
  return instance({
    url: '/general/signUp',
    method: 'post',
    params,
  });
};
//作品详情
export const reqWorkDetail = (params) => {
  return instance({
    url: '/general/detail',
    method: 'get',
    params,
  });
};
//发表评论
export const reqCommentSave = (params) => {
  return instance({
    url: '/general/postCommit',
    method: 'post',
    params,
  });
};
//评论列表
export const reqComment = (params) => {
  return instance({
    url: '/general/commits',
    method: 'get',
    params,
  });
};
//评论点赞
export const reqCommentLike = (params) => {
  return instance({
    url: '/general/commitLike',
    method: 'post',
    params,
  });
};
//评论踩
export const reqCommentDisLike = (params) => {
  return instance({
    url: '/general/dislike',
    method: 'post',
    params,
  });
};
// 投票
export const reqVote = (params) => {
  return instance({
    url: '/general/vote',
    method: 'post',
    params,
  });
};
// 我的投票
export const reqMyVote = (params) => {
  return instance({
    url: '/general/getVote',
    method: 'post',
    params,
  });
};

// 分享
export const reqShare = (params) => {
  return instance({
    url: '/general/share',
    method: 'post',
    params,
  });
};

/**
 * 微信签名
 * @returns
 */
export const getConfigData = (params) => {
  return axios({
    url: `https://wx.sanguosha.com/api/wechat/jssdk`,
    method: 'POST',
    params,
  })
    .then((response) => {
      const { status, data } = response;
      if (status !== 200) {
        return new Error(`${status}`);
      }
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
};
