import Router from '@/router';
import { getConfigData } from '@/api/http';
import route from '@/router';
import Vue from 'vue';
var vm = new Vue();

import { config } from '@/common/const';
import sharePic from '@/assets/images/logo.png';
var wx = require('weixin-js-sdk');

/** 获取Url后面的参数
 * @param {string} name 名称
 */
export const getParams = (name) => {
  var url = decodeURI(location.search); //获取url中"?"符后的字串
  var theRequest = new Object();
  if (url.indexOf('?') != -1) {
    var str = url.substr(1);
    var strs = str.split('&');
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1];
    }
  }
  if (theRequest.length <= 0) {
    return null;
  }
  return theRequest[name];
};

// 下载游戏
export const downGame = () => {
  function isWeiXin() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true;
    } else {
      return false;
    }
  }

  // reqDownload()
  //   .then((res) => {})
  //   .catch((err) => {
  //     // this.$Alert.show('出错了');
  //   });
  var iosUrl = 'https://ad.hzyoka.com/collect/landing/zii0nj';
  var androidUrl = 'https://ad.hzyoka.com/collect/landing/zii0nl';
  var defaultUrl = 'http://www.sanguosha.com';
  setTimeout(function () {
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      if (isWeiXin()) {
        $('.downpop').show();
      } else {
        window.location = androidUrl;
      }
    } else if (isiOS) {
      window.location = iosUrl;
    } else {
      window.location = defaultUrl;
    }
  }, 200);
};

// 签名
export const signature = () => {
  let url = encodeURIComponent(location.href.split('#')[0]);
  let jsApiList = ['startRecord', 'stopRecord', 'onVoiceRecordEnd', 'playVoice', 'pauseVoice', 'stopVoice', 'onVoicePlayEnd', 'uploadVoice', 'downloadVoice', 'updateAppMessageShareData', 'updateTimelineShareData'];
  let title = '《三国杀OL》首届方言配音大赛开启！  ';
  let desc = '【三国方言大赏】邀请您参加，快来选择一位武将为他配音吧！';
  let link = config.url;
  let imgUrl = config.baseUrl + '/localism/' + sharePic;
  getConfigData({ url, apis: jsApiList })
    .then((res) => {
      let data = res;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList: jsApiList, // 必填，需要使用的JS接口列表
      });

      wx.ready(function () {
        //分享给朋友及分享到QQ
        wx.updateAppMessageShareData({
          title, // 分享标题
          desc, // 分享描述
          link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl, // 分享图标
          success: function () {
            // 设置成功
          },
        });
        // 分享到朋友圈及分享到QQ空间
        wx.updateTimelineShareData({
          title, // 分享标题
          link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl, // 分享图标
          success: function () {
            // 设置成功
          },
        });
        // 分享到腾讯微博
        wx.onMenuShareWeibo({
          title, // 分享标题
          desc, // 分享描述
          link, // 分享链接
          imgUrl, // 分享图标
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });
      });
      wx.error(function (res) {
        vm.$toast.show(res.errMsg);
      });
    })
    .catch((err) => {
      console.log(err);
      vm.$toast.show('签名出错了');
    });
};
/** 判断pc端还是h5端访问页面
 * @param {string} pc pc路径名称
 * @param {string} h5 h5路径名称
 */
export const judgePc = (pc, h5) => {
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.match(/(iphone|ipod|ipad|android|blackberry|bb10|windows phone|tizen|bada)/)) {
    Router.push({ name: h5 });
  } else {
    Router.push({ name: pc });
  }
};
export function debounce(fn, interval) {
  var timer;
  var gapTime = interval || 1000;//间隔时间，如果interval不传，则默认1000ms
  return function() {
    clearTimeout(timer);
    var context = this;
    var args = arguments;//保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
    timer = setTimeout(function() {
      fn.call(context,args);
    }, gapTime);
  };
}
export const isChannel = () => {
  const { query: { channel_id } } = Router.currentRoute

  const officialList = ['210100', '110101', '410028', '310027', 'true', undefined]

  return !officialList.includes(channel_id)
}
/** 判断pc端还是h5端访问页面
 * @param {string} pc pc路径名称
 * @param {string} h5 h5路径名称
 */
export const judgeWeb = () => {
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.match(/(iphone|ipod|ipad|android|blackberry|bb10|windows phone|tizen|bada)/)) {
    return 'h5';
  } else {
    return 'pc';
  }
};
