import axios from 'axios';
import { config } from '@/common/const';

//1.创建 axios 实例
const instance = axios.create({
  baseURL: config.baseUrl,
  timeout: 10000, //超时时间
});

//2.1 添加 axios 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // console.log(config);

    //-----------发送请求前做些什么-------------

    //判断是否在微信里
    // let ua = navigator.userAgent.toLowerCase();
    // let isWeixin = ua.indexOf("micromessenger") != -1;
    // let iswx = 'no';
    // if (isWeixin) {
    //     iswx = 'yes';
    // }
    if (config.token) {
      //不需要token
      config.headers = {
        'Content-Type': 'application/json;charset=utf-8',
        // 'x-wx': iswx
      };
    } else {
      //登录后的请求就带上token
      let token = localStorage.getItem('token') || '';
      config.headers = {
        'Content-Type': 'application/json;charset=utf-8',
        // Authorization: token,
        // 'x-wx': iswx
      };
    }

    // 'GET'时参数名叫params,'PUT', 'POST', 和 'PATCH'时传data
    //处理前
    if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
      config.data = config.params;
      config.params = {}; //不让url后面跟参数
      let token = localStorage.getItem('token') || '';
      if (token) {
        config.data.token = token;
      }
    } else {
      //可删掉
      config.data = { ...config.params };
    }

    //开始加载动画
    // loadingInstance = Loading.service({
    //     text:'拼命加载中...',
    //     background:'rgba(255,255,255,.7)'
    // });
    return config; //返回结果
  },
  (error) => {
    // 对请求错误做些什么
    console.log(error);
  }
);

//2.2 添加 axios 响应拦截器
instance.interceptors.response.use(
  (res) => {
    console.log(res);
    // 对响应数据做点什么
    if (res.status === 200) {
      //关闭加载动画
      // loadingInstance.close()

      //在这里面可以做很多处理比如http状态码的判断

      //token过期 token失效 未登录
      // if (res.data.code === 401) {
      //   console.log('401');
      //   // this.props.history.replace('/login');
      //   // message.error('请先登录')
      //   goLogin();
      //   return;
      // }
      return res.data;
    } else {
      console.log('res', res);
    }
  },
  (error) => {
    // 对响应错误做点什么
    console.log(error);
  }
);

// 3.发送真正的网络请求
export default instance;
