<template>
  <div>
    <!-- 通行证登录 -->
    <div class="fade" @click="onCloseLogin"></div>
    <div class="pop-login" :class="agent" id="login">
      <div class="login-tab">
        <div class="ta first" :class="tab == 1 ? 'active' : ''" @click="onChangeTab($event, 1)" v-if="!isChannel()">
          <span>游卡通行证</span>
        </div>
        <div class="ta last" :class="tab == 2 ? 'active' : ''" @click="onChangeTab($event, 2)"><span>手机快捷登录</span></div>
      </div>
      <div :class="agent == 'pc' ? 'login-pc' : 'login-h5'" v-show="tab == 1 && !isChannel()">
        <div class="close" @click="onCloseLogin"></div>
        <div class="SGS_login-socalwrap" style="display: none;" id="toggleThirdLogin">
          <a href="javascript:;" data-type="feihuo" title="飞火登录" rel="nofollow">feihuo</a>
          <a href="javascript:;" data-type="baidu" title="百度登录" rel="nofollow">baidu</a>
          <a href="javascript:alert('目前人人账号授权登录暂停，如遇登录问题，可联系三国杀官网客服解决!');" title="人人登录" rel="nofollow">renren</a>
          <a href="javascript:;" data-type="weibo" title="微博登录" rel="nofollow">weibo</a>
          <a href="javascript:;" data-type="wechat" title="微信登录" rel="nofollow">wechat</a>
          <a href="javascript:;" data-type="qq" title="QQ登录" rel="nofollow">qq</a>
        </div>
        <div id="sgsPassApp"></div>
      </div>
      <div :class="agent == 'pc' ? 'login-pc' : 'login-h5'" v-show="tab == 2">
        <div class="close" @click="onCloseLogin"></div>
        <div class="dobest_login_form">
          <div class="dobest_error" id="error"></div>
          <div class="dobest_field dobest_username dobest_input_focus">
            <div class="login-title">账号：<span class="ic-phone"></span></div>
            <input type="text" class="dobest_input" tabindex="1" maxlength="11" id="phone" placeholder="输入手机号"
              @input="keyInput($event, 'phone')" />
          </div>
          <div class="dobest_field dobest_pwd">
            <div class="login-title">密码：<span class="ic ic-code"></span></div>
            <input type="text" class="dobest_input input_code" tabindex="2" maxlength="10" id="smscode"
              placeholder="请输入验证码" @input="keyInput($event, 'code')" />
            <button type="button" class="btn-code" @click="onSmsCode" id="btnSmsCode" ref="btnSmsCode" :class="btnClass"
              :disabled="disabled">{{ btnText }}</button>
          </div>
          <div class="dobest_extra">
            <label class="dobest_ex_checkbox"><input type="checkbox" name="autoLogin" /></label><em></em>
          </div>
          <div class="dobest_txt">
            <a href="http://pwd.dobest.cn/?pwdchoose=findpwd" target="_blank">忘记密码</a>
            <a href="https://splus2.dobest.cn/register?appId=205&amp;tabIndex=username&amp;returnUrl=http://web.sanguosha.com/login/index.html"
              target="_blank">注册账号</a>
          </div>
          <div class="dobest_field_btn"><a class="dobest_de_btn" href="javascript:;" @click="onLoginPhone"></a></div>
          <div class="user_degree checked" id="protocol_wrap"><input type="checkbox" id="protocol"
              class="mycheckbox checked" checked="checked" /><label class="mymask" for="protocol"></label>已阅读并同意《<a
              href="http://www.sanguosha.com/act/yhxy/regservice.html" target="_blank" class="user_yx">用户协议</a>》和《<a
              href="https://www.sanguosha.com/static/pc/dist/privacy.html" target="_blank" class="user_prisy">隐私协议</a>》
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reqSmsCode, reqLoginPhone } from '@/api/http';
import { config } from '@/common/const';
import { judgeWeb,isChannel } from '@/common/util';
import $ from 'jquery';
export default {
  name: 'Login',
  data() {
    return {
      tab: 1,
      phone: '',
      code: '',
      agent: 'pc',
      btnText: '获取验证码',
      btnClass: '',
      disabled: false,
      config,isChannel
    };
  },
  mounted() {
    //渠道版本没有游卡通行证
    if (!isChannel()) {

      this.onLogin();
    } else {
      this.tab = 2;
    }

    //判断pc还是h5
    this.agent = 'pc';
  },
  methods: {
    // 登录tab
    onChangeTab(e, type) {
      this.tab = type;
    },
    // input
    keyInput(e, type) {
      this[type] = e.target.value;
    },
    // 通行证登录
    onLogin() {
      var that = this;

      $sgsPass({
        container: "#sgsPassApp", // 默认ID
        // appId: 485,
        // captchaAppId: "10056", // 滑动验证码申请的APPID 默认为10056
        // locale: ELocale.zn, // 默认：zh_CN，繁体 tianwan ,英文: en
        // storageType: 'cookie', // 默认为localStorage 数据存取方式
        loginSuccess: (ticket, resData) => {
          $.ajax({
            type: 'post',
            url: config.baseUrl + '/index/ykLogin',
            datetype: 'json',
            data: { ticket: ticket },
            success: function (res) {
              var data = res.data;
              // that.$parent.loadMyInfo();
              localStorage.setItem('token', data);
              that.changeParent(data);
              // that.$router.push({ path: '/' });
            },
            fail: function (res) {
              //未登录/过期
              if (res.status == 401) {
                this.$router.push({ path: '/', query: { login: 0 } });
              } else {
                that.$toast.show(res.msg);
              }
            },
          });
        }
      })

    },
    // 获取验证码
    onSmsCode() {
      let { phone } = this;
      if (!$.trim(phone)) {
        this.$toast.show('请输入手机号');
        return false;
      }
      if (phone.length != 11) {
        this.$toast.show('手机号码格式不正确！');
        return false;
      }
      var sec = 60;
      reqSmsCode({ phone })
        .then((res) => {
          if (res.status == 0) {
            this.disabled = true;
            this.btnClass = 'btn-gray';
            var interval = setInterval(() => {
              if (sec == 0) {
                this.btnText = '重新获取';
                this.btnClass = '';
                this.disabled = false;
                clearInterval(interval);
                return;
              }
              this.btnText = sec + 's';
              sec--;
            }, 1000);
          } else if (res.status == -1) {
            this.$router.push({ path: '/', query: { login: 0 } });
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.show('网络出错了');
        });
    },
    // 手机号登录
    onLoginPhone() {
      let { phone, code } = this;
      if (!$.trim(phone)) {
        this.$toast.show('请输入手机号');
        return false;
      }
      if (phone.length != 11) {
        this.$toast.show('手机号码格式不正确！');
        return false;
      }
      if (!$.trim(code)) {
        this.$toast.show('请输入验证码');
        return false;
      }
      reqLoginPhone({ phone, msg_code: code })
        .then((res) => {
          if (res.status == 0) {
            let data = res.data;
            localStorage.setItem('token', data);
            this.changeParent(data);
            // this.$router.push({ path: '/' });
          } else if (res.status == -1) {
            this.$router.push({ path: '/', query: { login: 0 } });
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.show('网络出错了');
        });
    },
    // 改变父组件元素
    changeParent() {
      this.$parent.isShowLogin = false;
      this.$parent.changeToken();
    },
    // 关闭登录框
    onCloseLogin() {
      this.$parent.isShowLogin = false;
    },
  },
};
</script>
<style>
.SGS_title-wrap {
  display: none;
}
</style>
