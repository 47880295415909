<template>
  <div class="header" :class="agent == 'h5' ? 'h5' : ''" @mouseleave="isHover = false">
    <div class="logo"><img src="~/assets/images/logo.png" /></div>
    <div class="nav">
      <router-link to="/index" class="nav-item " :class="now == 1 ? 'active' : ''"><span class="ic-text ic-home"></span><span class="hover"></span></router-link>
      <router-link to="/rule" class="nav-item" :class="now == 2 ? 'active' : ''"><span class="ic-text ic-rule"></span><span class="hover"></span></router-link>
      <router-link to="/list" class="nav-item" :class="now == 3 ? 'active' : ''"><span class="ic-text ic-int"></span><span class="hover"></span></router-link>
      <a href="javascript:;" class="nav-item" :class="now == 4 ? 'active' : ''" @click="onJumpWork"><span class="ic-text ic-work"></span><span class="hover"></span></a>
      <!-- <a :href="link" class="nav-item" :class="now == 5 ? 'active' : ''"><span class="ic-text ic-hd"></span><span class="hover"></span></a> -->

      <!-- <a href="https://www.zcool.cn/events/sanguosha" target="_blank" class="nav-item last" :class="now == 6 ? 'active' : ''"><span class="ic-text ic-pf"></span><span class="hover"></span></a> -->
    </div>
    <div class="nav-other">
      <a href="javascript:;" class="ic ic-music" :class="this.$parent.$parent.isPlay ? 'active' : 'paused'" @click="onMusic">
        <i class="ic-m"></i>
        <i class="ic-close"></i>
      </a>
      <!-- <audio src="~/assets/images/Mix_7.5.mp3" controls :hidden="true" loop="loop" preload="auto" ref="music">您的浏览器不支持 audio 标签。</audio> -->
      <a href="javascript:;" class="ic-user" v-if="token" @click="onLogout">已登录</a>
      <a href="javascript:;" class="ic-user" @click="showLogin" v-else>登录</a>
      <a href="https://www.sanguosha.com/" target="_blank" class="ic ic-company" v-if="!isChannel()">官网</a>
      <a href="https://www.sanguosha.com/download" target="_blank" class="ic ic-download" v-if="!isChannel()">下载</a>
      <a href="javascript:;" class="ic ic-share" @click="onShare" :data-clipboard-text="shareText" id="equSN"
        >分享
        <div class="share-tips" v-show="isShowShareTips"><span class="sanjiao"></span>复制成功 快去分享吧~</div>
      </a>
      <!-- <a href="javascript:;" class="ic ic-share" @mouseover="isHover = true"
        >分享
        <div class="share-tips" v-show="isShowShareTips"><span class="sanjiao"></span>复制成功 快去分享吧~</div>
      </a> -->
      <!--  @mouseleave="isHover = false" -->
      <!-- <div class="shares animate__animated animate__fadeInRight" v-if="isHover" @click="onShare" :data-clipboard-text="shareText" id="equSN">
        <a href="javascript:;" class="ic ic-wechat animate__animated animate__fadeInRight show1">微信</a>
        <a href="javascript:;" class="ic ic-qq animate__animated animate__fadeInRight show2">qq</a>
        <a href="javascript:;" class="ic ic-qzone animate__animated animate__fadeInRight show3">qq空间</a>
      </div> -->
    </div>
    <Login v-show="isShowLogin" />
  </div>
</template>
<script>
import '@/assets/css/header.less';
import Login from './login.vue';
import Clipboard from 'clipboard';
import { config } from '@/common/const';
import { judgeWeb ,isChannel} from '@/common/util';

export default {
  name: 'Header',
  props: {
    now: {
      type: Number,
      default: 1,
    },
    page: {
      type: String,
      default: 'index',
    },
  },
  components: {
    Login,
  },
  data() {
    return {
      token: '',
      isShowLogin: false, //登录弹框是否显示
      isShowShareTips: false, //分享成功提示框
      shareText: config.shareInfo, //分享文案
      isHover: false,
      agent: 'pc', //是手机访问还是电脑
      config,isChannel,
      link: 'https://sgs10thmusic2023.sanguosha.com',
    };
  },
  mounted() {
    this.token = localStorage.getItem('token');
    if (isChannel()) {
      this.shareText = config.channelShareInfo;
      this.link = this.link += '/#/index?channel_id=false';
    }
    //判断pc还是h5

    this.agent = 'pc';
  },
  methods: {
    showLogin() {
      this.isShowLogin = true;
    },

    //跳转我的作品
    onJumpWork() {
      if (!this.token) {
        this.showLogin();
      } else {
        this.$router.push({ path: '/work', login: 0 });
      }
    },
    //分享
    onShare() {
      let that = this;
      let clipboard = new Clipboard('#equSN');
      clipboard.on('success', (e) => {
        // this.$toast.show('已复制到粘贴板,快去分享吧！');
        that.isShowShareTips = true;
        setTimeout(() => {
          that.isShowShareTips = false;
        }, 1000);
        // 释放内存
        clipboard.destroy();
      });

      clipboard.on('error', (e) => {
        this.$toast.show('该浏览器不支持复制');
        clipboard.destroy();
      });
    },
    //播放暂停音乐
    onMusic() {
      let myradio = this.$parent.$parent.$refs.music;
      if (myradio.paused) {
        myradio.play();
        this.$parent.$parent.isPlay = true;
      } else {
        myradio.pause();
        this.$parent.$parent.isPlay = false;
      }
    },
    //改变token
    changeToken() {
      let token = localStorage.getItem('token');
      this.token = token;
      this.$parent.token = token;
      if (this.page == 'detail') {
        this.$parent.loadMyVote();
      }
      if (this.page == 'work') {
        this.$parent.loadMyVote();
        this.$parent.loadWorkList();
      }
    },
    onLogout() {
      localStorage.clear();
      this.token = '';
      this.$parent.token = '';
      if (this.page == 'detail') {
        this.$parent.vote = 0;
      }
      if (this.page == 'work') {
        this.$parent.vote = 0;
        this.$parent.haveData = false;
      }
    },
  },
};
</script>
