//常量
let baseUrl = '';

//判断环境
switch (process.env.NODE_ENV) {
  case 'dev': //开发环境url
    baseUrl = process.env.VUE_APP_BASE_URL;
    console.log('当前环境：' + process.env.VUE_APP_BASE_URL);
    break;
  case 'production':
    //生产环境url
    baseUrl = process.env.VUE_APP_BASE_URL;
    // baseUrl = window.location.protocol + '//' + window.location.host; //生产环境url,自动获取当前域名
    break;
}
let baseShareInfo = '晦谋独善，一将成名！2024武将设计大赛现已开启，快来为我的作品投票吧~' + window.location.protocol + '//' + window.location.host;
let mShareInfo = '晦谋独善，一将成名！万千用户共创，2024武将设计大赛现已盛大启幕，谋系列武将与锦囊牌首次开放设计！奖励丰厚，等你来投稿！' + window.location.protocol + '//' + window.location.host;
export const config = {
  baseUrl,
  url: baseUrl + '/#/index',
  shareInfo: baseShareInfo + '/#/index',
  channelShareInfo: baseShareInfo + '/#/index?channel_id=false',
  shareMyInfo: mShareInfo + '/#/index',
  channelShareMyInfo: mShareInfo + '/#/index?channel_id=false',
  startTime: '2024/06/22 09:00:00', //投稿开始日
  // startTime: '2023/07/07 16:00:00', //投稿开始日
  channel: false, //是否是渠道
};
